@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";


$name: '.CaoContainerFluid';

#{$name} {
    &-whiteText {
        color: white !important;
    }

    &-whiteText a {
        color: white !important;
    }
}
